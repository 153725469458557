import { CommercialSpace, FieldOptionsList } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { SelectableOption } from './selectable-option';


export function getFieldOptions(list: FieldOptionsList, ds: DataService): SelectableOption[] {

	if (list == FieldOptionsList.Gender) {
		return [
			{ value: 'F', text: 'Female' },
			{ value: 'M', text: 'Male' },
			{ value: 'N', text: 'Non-Binary' },
			{ value: 'O', text: 'Other', showOtherValueField: true },
		];
	}

	else if (list == FieldOptionsList.Hispanic) {
		return [
			{ value: 'Y', text: 'I am Hispanic or Latino' },
			{ value: 'N', text: 'I am NOT Hispanic or Latino' }
		];
	}

	else if (list == FieldOptionsList.NativeEnglish) {
		return [
			{ value: 'Y', text: 'I am a Native English Speaker' },
			{ value: 'N', text: 'I am NOT a Native English Speaker' }
		];
	}

	else if (list == FieldOptionsList.NativeSpanish) {
		return [
			{ value: 'Y', text: 'I am a native Spanish Speaker' },
			{ value: 'N', text: 'I am NOT a native Spanish Speaker' }
		];
	}

	if (list == FieldOptionsList.No_Yes) {
		return [
			{ value: 'Y', text: 'Yes' },
			{ value: 'N', text: 'No' },
		];
	}

	else if (list == FieldOptionsList.Races) {
		return [
			{ value: 'N', text: 'American Indian or Alaska Native' },
			{ value: 'A', text: 'Asian' },
			{ value: 'B', text: 'Black or African American' },
			{ value: 'P', text: 'Native Hawaiian or Other Pacific Islander' },
			{ value: 'W', text: 'White' },
			{ value: 'O', text: 'Other', showOtherValueField: true },
		];
	}

	else if (list == FieldOptionsList.Veteran) {
		return [
			{ value: 'Y', text: 'I am a U.S. Veteran' },
			{ value: 'N', text: 'I am NOT a U.S. Veteran' }
		];
	}


	else if (list == FieldOptionsList.CompanyType) {
		const options: SelectableOption[] = ds.domain.companyType.getAll().reduce((options, companyType) => {
			options.push({
				text: companyType.name,
				value: companyType.companyTypeId,
			});
			return options;
		}, []);

		return options;
	}


	else if (list == FieldOptionsList.IndustryType) {
		const options: SelectableOption[] = ds.domain.industry.getAll().reduce((options, industryType) => {
			options.push({
				text: industryType.name,
				value: industryType.industryId,
			});
			return options;
		}, []);

		return options;
	}


	else if (list == FieldOptionsList.EmploymentStatus) {
		return [
			{ value: 1, text: 'Unemployed' },
			{ value: 2, text: 'Underemployed' },
			{ value: 3, text: 'Employed' },
			{ value: 4, text: 'Retired' },
		];
	}


	else if (list == FieldOptionsList.HeadOfHousehold) {
		return [
			{ value: 'Y', text: 'I am Head of Household' },
			{ value: 'N', text: 'I am NOT Head of Household' }
		];
	}

	else if (list == FieldOptionsList.Offerings) {
		return [
			{ value: 'Products', text: 'Products' },
			{ value: 'Services', text: 'Services' },
			{ value: 'Both', text: 'Products and Services' },
		];
	}
	else if (list == FieldOptionsList.Involvement) {
		return [
			{ value: 'Y', text: 'I work on this business full-time' },
			{ value: 'N', text: 'I work on this business part-time' }
		];
	}
	if (list == FieldOptionsList.CommercialSpace) {
		return [
			{ value: CommercialSpace.Own, text: 'Yes, I own commercial space' },
			{ value: CommercialSpace.Rent, text: 'Yes, I rent commercial space' },
			{ value: CommercialSpace.None, text: 'No, I do not own or rent commercial space' },
		];
	}
}