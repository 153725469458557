import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DestroyablePart } from '@me-access-parts';
import { DbcUser, PersonDemographics } from '@me-interfaces';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { FuncService } from '@me-services/core/func';
import { DialogData, DialogService } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { Subscription } from 'rxjs';
import { OptOutDemographicsDialog } from './SHR-ED_opt-out-demographics.dialog';

@Component({
	selector: 'person-demographics-editor',
	templateUrl: './SHR-ED_demographics-editor.part.html',
	styleUrls: ['./SHR-ED_demographics-editor.part.scss'],
})
export class PersonDemographicsEditorPart extends DestroyablePart implements OnInit {

	@Input() personId: number;
	@Input() adminAccess = false;
	demographics: PersonDemographics;
	form: FormGroup;
	updating = false;

	canTriggerEnTag = false;
	canTriggerEsTag = false;

	optOutDemographicsDisabled = false;
	deceasedDisabled = false;
	handicappedDisabled = false;
	userSubscription: Subscription;

	public defaultGender = undefined;
	public genders = [
		{ code: 'F', text: 'Female' },
		{ code: 'M', text: 'Male' },
		{ code: 'N', text: 'Non-Binary' },
		{ code: 'O', text: 'Other' },
	];

	public defaultRace = undefined;
	public racesOptions = [
		{ code: 'N', text: 'American Indian or Alaska Native' },
		{ code: 'A', text: 'Asian' },
		{ code: 'B', text: 'Black or African American' },
		{ code: 'P', text: 'Native Hawaiian or Other Pacific Islander' },
		{ code: 'W', text: 'White' },
		{ code: 'O', text: 'Other' },
	];

	public defaultHispanic = undefined;
	public hispanic = [
		{ code: 'Y', text: 'I am Hispanic or Latino' },
		{ code: 'N', text: 'I am NOT Hispanic or Latino' }
	];

	public defaultVeteran = undefined;
	public veterans = [
		{ code: 'Y', text: 'I am a U.S. Veteran' },
		{ code: 'N', text: 'I am NOT a U.S. Veteran' }
	];


	public defaultNativeEnglish = undefined;
	public nativeEnglish = [
		{ code: 'Y', text: 'I am a Native English Speaker' },
		{ code: 'N', text: 'I am NOT a Native English Speaker' }
	];

	public defaultNativeSpanish = undefined;
	public nativeSpanish = [
		{ code: 'Y', text: 'I am a native Spanish Speaker' },
		{ code: 'N', text: 'I am NOT a native Spanish Speaker' }
	];

	inlineLabels = this.labels.trackInlineLabels(this, [
		'Other Gender',
		'Other Race(s)',
		'Too many characters',
		...this.genders.map(p => p.text),
		...this.racesOptions.map(p => p.text),
		...this.hispanic.map(p => p.text),
		...this.veterans.map(p => p.text),
		...this.nativeEnglish.map(p => p.text),
		...this.nativeSpanish.map(p => p.text),
	]);

	constructor(
		private fb: FormBuilder,
		private func: FuncService,
		private labels: LabelsService,
		private dialogService: DialogService,
		private userService: OldUserService,
	) {
		super();
	}

	async ngOnInit() {
		super.initDestroyable();

		this.demographics = await this.func.user.getDemographics({ personId: this.personId });
		if (this.adminAccess) this.setDefaults();
		await this.createForm();
	}

	setDefaults() {
		this.defaultGender = this.defaultRace = this.defaultHispanic = this.defaultVeteran = this.defaultNativeEnglish = this.defaultNativeSpanish = { code: undefined, text: '' };
	}

	private async createForm() {

		this.form = this.fb.group({
			gender: [undefined],
			genderOther: ['', [Validators.maxLength(45)]],
			races: [undefined],
			raceOther: ['', [Validators.maxLength(45)]],
			hispanic: [undefined],
			birth: ['', this.checkIsAdult],
			veteran: [undefined],
			nativeEnglish: [undefined],
			nativeSpanish: [undefined],
			handicapped: [false],
			hasOptOutDemographics: [false],
			isDeceased: [false],
		});
		await this.resetForm();
	}

	private checkIsAdult(ctrl: AbstractControl): ValidationErrors | null {
		const v = ctrl.value;
		if (v) {
			const year = v.getFullYear();
			const month = v.getMonth(); //0 - 11
			const day = v.getDate();
			const rightNow = new Date();
			if (new Date(year + 3, month, day) > rightNow) return { tooYoung: true };
			if (new Date(year + 120, month, day) < rightNow) return { tooOld: true };
			return null;
		}

		return null;
	}

	public async resetForm() {
		const d = this.demographics;
		let birth = undefined;

		if (d.birthYear && d.birthDay) {
			birth = new Date(
				d.birthYear,				// year e.g. 1900
				Math.floor(d.birthDay / 100) - 1,	// zero-based month e.g. 11 for December
				d.birthDay % 100,			// day of month
				12, 0, 0, 0					// noon
			);
		}

		if (d.nativeEnglish === null) this.canTriggerEnTag = true;
		if (d.nativeSpanish === null) this.canTriggerEsTag = true;

		const values = {
			gender: d.gender,
			genderOther: d.genderOther || '',
			races: d.races,
			raceOther: d.raceOther || '',
			hispanic: d.hispanic || undefined,
			birth: birth || '',
			veteran: d.veteran || undefined,
			nativeEnglish: d.nativeEnglish || undefined,
			nativeSpanish: d.nativeSpanish || undefined,
			handicapped: d.handicapped || false,
			hasOptOutDemographics: d.hasOptOutDemographics || false,
			isDeceased: d.isDeceased || false,
		};

		this.form.reset(values);

		Object.keys(this.form.controls).forEach(key => {
			const ctrl: AbstractControl = this.form.get(key);
			ctrl.markAsTouched();
		});

		this.form.updateValueAndValidity();
		await this.enableCheckboxes()
	}

	private async enableCheckboxes() {

		let user: DbcUser;

		this.userSubscription = this.userService.user$.subscribe(u => user = u);

		const editingCurrentUser = user.personId == this.personId;
		const demEditor = user.canEditDemographics;

		this.deceasedDisabled = editingCurrentUser || !demEditor;
		this.optOutDemographicsDisabled = !demEditor;
		this.handicappedDisabled = !demEditor;
	}

	toggleCheckbox(box: string) {
		let ctrl: AbstractControl = undefined;

		if (box == 'D') ctrl = this.form.controls.isDeceased;
		if (box == 'O') ctrl = this.form.controls.hasOptOutDemographics;
		if (box == 'H') ctrl = this.form.controls.handicapped;

		const checked = !ctrl.value;

		ctrl.patchValue(checked);
		ctrl.markAsDirty();
		this.enableCheckboxes();
	}

	async save() {
		this.updating = true;
		const values = Object.assign(this.form.value);
		if (values.birth) {
			const birth: Date = values.birth;
			values.birthYear = birth.getFullYear();
			values.birthDay = (birth.getMonth() + 1) * 100 + birth.getDate();
		}
		values.isDeceased = !!values.isDeceased ? 'Y' : 'N';
		values.handicapped = !!values.handicapped ? 'Y' : 'N';
		values.hasOptOutDemographics = !!values.hasOptOutDemographics ? 'Y' : 'N';

		const triggers = {
			canTriggerEnTag: this.canTriggerEnTag,
			canTriggerEsTag: this.canTriggerEsTag,
		}

		this.demographics = await this.func.user.updateDemographics({ personId: this.personId, values, triggers });
		this.resetForm();
		this.updating = false;
	}

	tooManyCharacters(ctrl: AbstractControl) {
		const e = ctrl.errors.maxlength;
		return `${this.inlineLabels['Too many characters']}. ${e.actualLength} > ${e.requiredLength}`;
	}

	async launchInfoDialog() {
		const data: DialogData<void> = {};
		await this.dialogService.showCustom(OptOutDemographicsDialog, data, 500, 310);
	}
}
