/**
 * A named list of options for a SelectFieldPart.
 * There must be a corresponding list of options in get-field-options.ts
 */
export const enum FieldOptionsList {
	Gender = 'Gender',
	Hispanic = 'Hispanic',
	NativeEnglish = 'NativeEnglish',
	NativeSpanish = 'NativeSpanish',
	No_Yes = 'No/Yes',
	Races = 'Races',
	Veteran = 'Veteran',
	CompanyType = 'CompanyType',
	EmploymentStatus = 'EmploymentStatus',
	HeadOfHousehold = 'HeadOfHousehold',
	Offerings = 'Offerings',
	IndustryType = 'IndustryType',
	Involvement = 'Involvement',
	CommercialSpace = 'CommercialSpace',
}