import { DbConceptName, DbcUser, UserBasicInfo } from "@me-interfaces";
import { OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from "@me-services/core/func";
import { PersonWrapper } from "../entity/person-wrapper";


/** @deprecated DO NOT USE THIS!*/
export class UserWrapper extends PersonWrapper implements DbcUser {

	private user: DbcUser;

	constructor(
		protected override person: DbcUser,
		protected override func: FuncService,
		protected override dd: OldDomainDataService,
	) {
		super(person, func, dd);
		this.user = person;
	}

	override readonly _concept: DbConceptName.Person;

	//
	// DbcUser interface
	//
	public get gender() { return this.user.gender; }
	public get genderOther() { return this.user.genderOther; }
	public get races() { return this.user.races; }
	public get raceOther() { return this.user.raceOther; }
	public get hispanic() { return this.user.hispanic; }
	public get birthYear() { return this.user.birthYear; }
	public get birthDay() { return this.user.birthDay; }
	public get veteran() { return this.user.veteran; }
	public get handicapped() { return this.user.handicapped; }
	public get nativeEnglish() { return this.user.nativeEnglish; }
	public get nativeSpanish() { return this.user.nativeSpanish; }
	public get alerts() { return this.user.alerts; }
	public get adminRoutes() { return this.user.adminRoutes; }




	// public async updateDemographics(values: Demographics) {
	// 	let u = await this.func.user.updateDemographics({ values });

	// 	user.gender = u.gender;
	// 	user.ethnicity = u.ethnicity;
	// 	user.ethnicityOther = u.ethnicityOther;
	// 	user.birthYear = u.birthYear;
	// 	user.birthMonth = u.birthMonth;
	// 	user.birthDay = u.birthDay;
	// 	user.veteran = u.veteran;
	// 	user.handicapped = u.handicapped;
	// 	user.nativeEnglish = u.nativeEnglish;
	// 	user.nativeSpanish = u.nativeSpanish;
	// }

	//
	// Update the user side(public) basic info
	//
	public async publicUpdateUserBasicInfo(user: DbcUser, values: UserBasicInfo) {
		const u = await this.func.user.updateBasicInfo({ values });
		user = Object.assign(user, u);
	}

	public async publicSetPrimaryEmail(user: DbcUser, emailId: number) {
		const person = await this.func.user.email.setPrimary({ emailId });
		user = Object.assign(user, person);
	}

	public async publicRemoveEmail(user: DbcUser, emailId: number) {
		const person = await this.func.user.email.remove({ entityId: user.entityId, emailId });
		user = Object.assign(user, person);
	}

	public async publicUpdateBio(user: DbcUser, languageId: number, newBio: string) {
		const person = await this.func.user.updateBio({ languageId, newBio });
		user = Object.assign(user, person);
	}

	public async addTag(user: DbcUser, tagId: number) {
		const person = await this.func.person.tags.add({ personId: user.personId, tagId });
		user = Object.assign(user, person);
	}

	public async removeTag(user: DbcUser, tagId: number) {
		const person = await this.func.person.tags.remove({ personId: user.personId, tagId });
		user = Object.assign(user, person);
	}
}